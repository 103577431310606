import React from "react";
import moment from "moment";
import { Button, Table } from "semantic-ui-react";
import { API_URL } from "../../../constants";

export default function Item({ item, onDecision }) {
  return (
    <Table.Row>
      <Table.Cell>
        <strong>{item.name}</strong>
        <br />
        <a download href={`${API_URL}/api/raise-requests/download/${item.id}`}>
          Download
        </a>
      </Table.Cell>
      <Table.Cell>
        {moment(item.effective_date).format("MM-DD-YYYY")}
      </Table.Cell>
      <Table.Cell>{moment(item.term_end_date).format("MM-DD-YYYY")}</Table.Cell>
      <Table.Cell>
        {moment(item.accept_rejected_at).format("MM-DD-YYYY")}
      </Table.Cell>

      {item.accept_reject === null && (
        <Table.Cell>
          <div className="table-actions">
            <Button
              color="green"
              className="btn-approve"
              onClick={() => {
                onDecision(item.id, "ACCEPT");
              }}
            >
              Approve
            </Button>
            <Button
              color="red"
              className="btn-deny"
              onClick={() => {
                onDecision(item.id, "DENY");
              }}
            >
              Deny
            </Button>
          </div>
        </Table.Cell>
      )}
      {item.accept_reject === false && (
        <Table.Cell>
          <div className="btn-denied">Denied</div>
        </Table.Cell>
      )}
      {item.accept_reject && (
        <Table.Cell>
          <div className="btn-approved">Approved</div>
        </Table.Cell>
      )}
    </Table.Row>
  );
}
