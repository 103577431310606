import React, { Component } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Link } from "react-router";
import { customerLogin, logout } from "../../actions/login";
import { Success } from "../../components/FlashMessage";
import { push } from "react-router-redux";

import "./PortalLogin.css";

class PortalLogin extends Component {
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onSignIn();
    }
  };

  onSignIn = () => {
    this.props.signIn(this.username.value, this.password.value).then(resp => {
      const login_redirect = get(this.props, "location.query.redirect_to");
      if (login_redirect) {
        this.props.dispatch(push(login_redirect));
        return;
      } else {
        this.props.dispatch(push("/portal"));
      }
    });
  };

  render() {
    const { errorMessage } = this.props;
    console.log("rendering");

    return (
      <div className="login-container" onKeyPress={this.handleKeyPress}>
        <div className="customer-login-box">
          <h1>Customer Login</h1>
          <p>
            Welcome Customers.
            <br />
            Please enter your credentials below to continue.{" "}
          </p>
          <div style={{ color: "red" }}>{errorMessage}</div>

          <Success
            msg={"Password set successfully. Please login to continue"}
            enabled={get(this.props.location, "query.resetsuccess", false)}
          />

          <Success
            msg={"Password Reset Successful.  Please check your inbox for your reset link. "}
            enabled={get(this.props.location, "query.didreset", false)}
          />

          <input
            className="textInput"
            type="text"
            ref={v => {
              this.username = v;
            }}
            placeholder="Email"
          />
          <input
            className="textInput"
            type="password"
            ref={v => {
              this.password = v;
            }}
            placeholder="Password"
          />

          <button className="btnLogin" onClick={this.onSignIn}>
            Sign In
          </button>
          <Link to="/forgot">Forgot Password</Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, token, errorMessage } = state.auth;
  return {
    token,
    isLoggedIn,
    errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    signIn(email, password) {
      return dispatch(customerLogin(email, password));
    },
    signOut() {
      return dispatch(logout());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalLogin);
