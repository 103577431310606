import cookie from "react-cookie";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
  // console.log("Query variable %s not found", variable);
}
export function getPublicAccessToken() {
  return getQueryVariable("public_access_token");
}

export function getClientToken() {
  return cookie.load("clientToken");
}
