import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import moment from "moment";
import n from "numeral";
import { Grid, Table } from "semantic-ui-react";

const toggle = state => {
  return {
    collapsed: !state.collapsed
  };
};

export default class DashboardTable extends React.Component {
  static propTypes = {
    expandable: PropTypes.bool
  };

  static defaultProps = {
    expandable: false
  };

  toggleCollapsed = () => this.setState(toggle);

  state = {
    collapsed: this.props.expandable ? true : false
  };
  render() {
    const { collapsed } = this.state;
    const { data = [], description, title, color = null, expandable } = this.props;
    const total = data.reduce((prev, cur) => prev + cur.quote_total, 0);

    const limit = expandable && collapsed ? 5 : data.length;
    return (
      <div className="table-group">
        <h3>
          {title} ({data.length})
        </h3>
        <p>{description}</p>
        <Table celled compact size="small" color={color} key={color} inverted={color ? "inverted" : null}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Quote #</Table.HeaderCell>
              <Table.HeaderCell>Job Name</Table.HeaderCell>
              <Table.HeaderCell width={2}>Total</Table.HeaderCell>
              <Table.HeaderCell width={2}>Created On</Table.HeaderCell>
              <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.slice(0, limit).map(item => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link to={`/portal/quotes/view/${item.id}`}>{item.quote_number}</Link>
                </Table.Cell>
                <Table.Cell>
                  <b>{item.name}</b>
                  <br />
                  {item.description}
                </Table.Cell>
                <Table.Cell>{n(item.quote_total).format("$0,0.00")}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format("MMM DD, YYYY")}</Table.Cell>
                <Table.Cell>
                  <Link to={`/portal/quotes/view/${item.id}`}>View</Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {expandable && (
          <Grid textAlign="center" padded>
            <Grid.Column>
              <button onClick={this.toggleCollapsed}>{this.state.collapsed ? "Show All" : "Show Less"}</button>
            </Grid.Column>
          </Grid>
        )}
        <Grid textAlign="right" padded>
          <Grid.Column>
            <b>Total: {n(total).format("$0,0.00")}</b>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
