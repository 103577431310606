import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';

import './PortalTop.css';

class PortalTop extends Component {
  render() {
    const { isLoggedIn } = this.props;
    return (
      <div className="portal-top-wrapper">

        <ul>
          {isLoggedIn && <li><Link to="/logout">Logout</Link></li>}
          {!isLoggedIn && <li><Link to="/login">Login</Link></li>}
        </ul>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  return {
    isLoggedIn,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalTop);
