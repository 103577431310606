import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Header, Icon, Modal, Table } from "semantic-ui-react";
import {
  approveRaiseRequest,
  denyRaiseRequest
} from "../../../actions/raise-requests";
import ListItem from "./ListItem";

function getAcceptVerbiage() {
  return (
    <p>
      By checking accept below, you acknowledge and agree that (i) Customer, as
      named in the Contract, accepts the terms of Contract, (ii) you have
      authority to bind Customer to the Contract, and (iii) that the{" "}
      <a href="/terms" target="_blank">
        Contract Terms and Conditions
      </a>{" "}
      are incorporated into the Contract and govern the Contract.
    </p>
  );
}

class PortalRaiseRequestList extends Component {
  state = {
    sort: {
      name: 0,
      description: 0
    },
    modalOpen: false
  };

  toItem = id => {
    const { entities } = this.props;
    const item = entities[id];
    if (!item) return;
    return <ListItem key={id} item={item} onDecision={this.showModal} />;
  };

  sortByName = () => this.toggleSort("name");

  sortyByDescription = () => this.toggleSort("description");

  toggleSort = key => {
    const pageNum = this.props.pageNum;
    const dir = this.state.sort[key] ? "desc" : "asc";
    this.props.getItems(pageNum, key, dir);
    this.setState({ sort: { [key]: !this.state.sort[key] } });
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleDecision = choice => {
    if (choice === "ACCEPT") {
      this.props.dispatch(approveRaiseRequest(this.state.raise_request_id));
    } else if (choice === "DENY") {
      this.props.dispatch(denyRaiseRequest(this.state.raise_request_id));
    }
    this.handleClose();
  };

  showModal = (id, choice) => {
    this.setState({
      raise_request_id: id,
      modalOpen: true,
      modalTitle:
        choice === "ACCEPT"
          ? "You are about to Approve this Raise Request"
          : "You are about to Deny this Raise Request",
      modalDescription: choice === "ACCEPT" ? getAcceptVerbiage() : ``,
      modalChoice: choice,
      modalConfirm:
        choice === "ACCEPT" ? "Yes, I Accept" : "Deny this Raise Request"
    });
  };

  render() {
    const { items } = this.props;
    const {
      modalOpen,
      modalTitle,
      modalDescription,
      modalChoice,
      modalConfirm
    } = this.state;

    return (
      <div className="quotes-list-container">
        <Modal open={modalOpen} onClose={this.handleClose} basic size="small">
          <Header icon="warning" content={modalTitle} />
          <Modal.Content>
            <p>{modalDescription}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={this.handleClose}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              inverted
              onClick={() => {
                this.handleDecision(modalChoice);
              }}
            >
              <Icon name="checkmark" /> {modalConfirm}
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.denyOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <Header
            icon="warning"
            content="You are about to Deny this RaiseRequest"
          />
          <Modal.Content />
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => {
                this.handleChoice("DENY");
              }}
            >
              <Icon name="remove" /> Cancel
            </Button>
            <Button color="green" inverted>
              <Icon name="checkmark" /> Yes, Deny this RaiseRequest
            </Button>
          </Modal.Actions>
        </Modal>

        <Table celled size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="quote-item-name">
                <strong className="sortable-header" onClick={this.sortByName}>
                  Job Name
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell>Effective Date</Table.HeaderCell>
              <Table.HeaderCell>Term End Date</Table.HeaderCell>
              <Table.HeaderCell>Approval Date</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Boolean(items.length) && items.map(this.toItem)}
            {!Boolean(items.length) && (
              <Table.Row>
                <Table.Cell colSpan="5">
                  <b>You have No Raise Requests pending approval.</b>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

PortalRaiseRequestList.defaultProps = {
  items: []
};

export default connect()(PortalRaiseRequestList);
