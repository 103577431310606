import { handleActions } from "redux-actions";
import { union } from "lodash";

const initialState = {
  quoteIds: [],
};

export default handleActions(
  {
    QUOTES_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          quoteIds: response.result.items,
          pagination: response.result.pagination,
        }
      );
    },

    QUOTEBYID_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          quoteIds: union(state.quoteIds, [response.result]),
        }
      );
    },
  },
  initialState
);
