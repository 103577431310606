import React from "react";
import { Button } from "semantic-ui-react";

export default function AcceptStatus({ accepted, onDecision, id }) {
  if (accepted === true || accepted === false) {
    return <div />;
  }

  return (
    <div className="table-actions">
      <Button
        color="green"
        className="btn-approve"
        onClick={() => {
          onDecision(id, "ACCEPT");
        }}
      >
        Approve
      </Button>
      <Button
        color="red"
        className="btn-deny"
        onClick={() => {
          onDecision(id, "DENY");
        }}
      >
        Deny
      </Button>
    </div>
  );
}
