import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { LOGIN_CHECK_MS } from "../../constants";
import { Button, Modal, Header } from "semantic-ui-react";
import { getLoggedInUser } from "../../actions/auth";
import { getPublicAccessToken } from "../../utils/token";
import ErrorPage from "../../components/ErrorPage";
import "./Portal.css";
import "./Navigation.css";
import SearchBar from "../Search/SearchBar";

import PortalTop from "./PortalTop";
// import Profile from "../Admin/Profile";

class Portal extends Component {
  state = {
    showLoginExpiredModal: false,
    showMobileMenu: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const interval = setInterval(() => {
      dispatch(getLoggedInUser()).catch(() => {
        this.setState({
          showLoginExpiredModal: true,
        });
        clearInterval(interval);
      });
    }, LOGIN_CHECK_MS);
  }

  hideMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  onHandleLoginClick() {
    window.location.href = `${process.env.REACT_APP_CLIENTS_APP_URL}/login?redirect_to=${window.location.href}`;
  }

  render() {
    const { user, is404 } = this.props;
    const { showLoginExpiredModal } = this.state;

    const public_access_token = getPublicAccessToken();

    if (is404) {
      return <ErrorPage />;
    }

    return (
      <div className="admin-wrapper">
        <Modal open={showLoginExpiredModal}>
          <Header icon="warning circle" content="Login Expired" />
          <Modal.Content>
            <p>Your login session has expired. Please login again in order to continue using the Portal</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.onHandleLoginClick}>
              Login
            </Button>
          </Modal.Actions>
        </Modal>
        <PortalTop />

        {/* <Profile /> */}

        <div
          className="ion-navicon-round mobile-burger-menu"
          onClick={() => {
            this.setState({ showMobileMenu: !this.state.showMobileMenu });
          }}
        />

        <div className={`mobile-menu mobile-menu-${this.state.showMobileMenu}`}>
          <ul>
            <li onClick={this.hideMenu}>
              <Link to="/portal/quotes">Quotes</Link>
            </li>
            {/* <li onClick={this.hideMenu}>
              <Link to="/portal/contracts">Contracts</Link>
            </li>
            <li onClick={this.hideMenu}>
              <Link to="/portal/raise-requests">Raise Requests</Link>
            </li> */}
            <li onClick={this.hideMenu}>
              <a href="https://goo.gl/forms/38MAyH1AITTVLLNs1" target="_blank" rel="noreferrer">
                Report a Bug
              </a>
            </li>
            <li onClick={this.hideMenu}>
              <a href="/logout">Logout</a>
            </li>
          </ul>
        </div>

        <SearchBar className="portal-search-bar" />

        <div className="portal-container">
          {!public_access_token && (
            <div className="sidebar">
              <div className="logo">
                <Link to="/">
                  <img src="/OCLM-Logo-Horizontal-2023.png" alt="OCLM Logo" width="180" />
                </Link>
              </div>

              <div className="navigation-email">
                {user.email}
                <br />
                id: {user.portal_manager_id}
                <div className="navigation-email-circle">
                  <svg viewBox="0 0 24 24" className="navigation-email-icon">
                    <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
                  </svg>
                </div>
              </div>

              <ul>
                <li onClick={this.hideMenu}>
                  <Link to="/portal/quotes">Quotes</Link>
                </li>
                {/* <li onClick={this.hideMenu}>
                  <Link to="/portal/contracts">Contracts</Link>
                </li>
                <li onClick={this.hideMenu}>
                  <Link to="/portal/raise-requests">Raise Requests</Link>
                </li> */}
                <li onClick={this.hideMenu}>
                  <a href="https://goo.gl/forms/38MAyH1AITTVLLNs1" target="_blank" rel="noreferrer">
                    Report a Bug
                  </a>
                </li>
                <li onClick={this.hideMenu}>
                  <a href="/logout">Logout</a>
                </li>
              </ul>
            </div>
          )}
          <div className="content content-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    is404: state.app.is404,
  };
}

export default connect(mapStateToProps)(Portal);
