import React from "react";
import { Container, Header } from "semantic-ui-react";

export default class Terms extends React.Component {
  render() {
    return (
      <Container text className="terms-container">
        <Header as="h2">Terms and Conditions</Header>
        <p>
          BY SIGNING THE QUOTE WHICH THESE TERMS AND CONDITIONS ARE THERETO ATTACHED OR OTHERWISE ACCOMPANY ("QUOTE") OR
          BY OTHERWISE ACCEPTING THE PROVISION OF ANY OF THE GOODS OR SERVICES RELATING TO THE QUOTE, CUSTOMER
          ACKNOWLEDGES AND AGREES TO BE BOUND BY THE TERMS AND CONDITIONS ("TERMS" AND COLLECTIVELLY WITH THE WITH THE
          QUOTE, "AGREEMENT") SET FORTH HEREIN. This Agreement comprises the entire agreement between the parties
          relating to the sale of the materials, if any, set forth on the Quote (the "Goods") and services, if any, set
          forth on the Quote ("Services"), and supersedes all prior or contemporaneous understandings, agreements,
          negotiations, and communications, both written and oral. These Terms prevail over any of Customer's general
          terms and conditions. Notwithstanding anything to the contrary herein, if a written monthly landscape
          maintenance agreement for the Property signed by both parties is in existence (a "Monthly Landscape
          Agreement") and expressly states its terms covers paid extra work such as the Goods and Services, the terms
          and conditions of said monthly landscape agreement shall prevail to the extent they are inconsistent with
          these Terms.
        </p>
        <p>
          <strong>1. General; Access and Cooperation:</strong>
          As used herein, (i) "<u>Contractor</u>" means O'Connell Landscape Maintenance Inc., a California corporation,
          (ii) "<u>Customer</u>" means the customer as set forth on the Quote, (iii) "<u>parties</u>" mean,
          collectively, Customer and Contractor, and (iv) "<u>Property</u>" means the property associated with Customer
          were the Goods are to be delivered and/or the Services are to be performed. Customer shall (i) cooperate with
          Contractor in all matters relating to the Goods or Services, and shall provide Contractor with access to the
          Property, (ii) if the provision of Services is near the Property boundary or any utilities, point out Property
          lines/boundaries or utilities, as applicable, to Contractor, and Customer shall be responsible for contacting
          Underground Service Alert to locate underground utility lines prior to start of the delivery of the Goods or
          performance of the Services, (iii) provide Contractor with copies of any applicable covenants, conditions, or
          restrictions relating to the Property which may be relevant to the Goods and Services, and (iv) respond
          promptly to any Contractor request to provide direction, information, approvals, authorizations or decisions
          that are reasonably necessary to Contractor to perform the Services.
        </p>

        <p>
          <strong>2. Tree-Specific Terms.</strong>
          In the event the Services contemplate tree services (inspection, removal, trimming, etc.), this{" "}
          <u>Section 2</u> shall apply. Trees removed will be cut reasonably close to the ground based on conditions. In
          the event unseen hazards are present (e.g., metal, concrete, etc.), Contractor may assess additional charges.
          Unless caused by Contractor's willful misconduct, Contractor shall not be responsible for damage to
          underground utilities (e.g., pipes, wires, cables, irrigation, etc.). Customer acknowledges, understands and
          agrees that requests for crown thinning in excess of 25% or work otherwise not in accordance with ISA
          standards: (i) is not advised or recommended by Contractor, (ii) poses significant risk which risk shall be
          solely borne by Customer, and (iii) to the fullest extent permitted by applicable law, Customer releases and
          waives any and all rights of recovery against Contractor for such Services. Without limiting the generality{" "}
          <u>Section 9</u> or <u>11</u>, Contractor is not responsible for unknown or otherwise hidden tree defects, and
          Contractor does not guarantee that trees will not fail in the future.
        </p>

        <p>
          <strong>3. Payment:</strong>
          Customer shall pay all invoiced amounts due to Contractor within fifteen (15) days from the date of
          Contractor's invoice. Contractor may bill in phases or otherwise provide progress billing. Contractor may
          include additional charges for unseen hazards or changes requested by the Customer. In the event of any
          dispute, Customer shall not be relieved from its obligation to pay for the Goods and Services as provided
          herein; furthermore, Customer shall not withhold payment of any amounts due and payable by reason of any
          set-off of any claim or dispute with Contractor. Customer shall pay interest on all late payments at the
          lesser of the rate of 1.5% per month or the highest rate permissible under applicable law, calculated daily
          and compounded monthly. As collateral security for the payment of the purchase price of the Goods and
          Services, Customer hereby grants to Contractor a lien on and security interest in and to all of the right,
          title and interest of Customer in, to, and under the Goods. To the fullest extent allowed by applicable law,
          Customer shall reimburse Contractor for all costs, fees and expenses incurred in collecting any late payments,
          including, without limitation, attorneys' fees, bank charges, and collection agency fees. In addition to all
          other remedies available under these Terms or at law (which Contractor does not waive by the exercise of any
          rights hereunder), Contractor shall be entitled to suspend the delivery of any Goods or performance of any
          Service (or any other services to Customer)("<u>Suspension</u>"), if Customer fails to pay any amounts when
          due hereunder and such failure continues for 10 days following written notice thereof.
        </p>

        <p>
          <strong>4. Force Majeure:</strong> Notwithstanding anything to the contrary in this Agreement, in no event
          shall Contractor be responsible or liable for any failure or delay the delivery of any Goods or performance of
          any Services or any other performance of its obligations under this Agreement (and, in addition, Contractor
          shall have no obligation whatsoever to provide warranty, guarantee, indemnity, or defense for matters) arising
          out of or caused, directly or indirectly, by circumstances beyond its reasonable control, including, without
          limitation, acts of God, earthquakes, fires, floods, storms, severe weather, wars, civil or military
          disturbances, acts of terrorism, sabotage, strikes, epidemics, riots, utility failures or interruptions,
          government order or regulation, government action, labor disputes, pre-existing conditions at the Property, or
          any other causes or circumstances beyond its reasonable control.
        </p>

        <p>
          <strong>5. Scheduling; Quote/Agreement Cancellations or Suspension.</strong> Scheduling of the delivery of
          Goods and performance of Services is dependent on weather conditions, workloads, Property condition, and other
          matters, and Contractor makes no guarantees to any timelines or completion dates that may be set forth on the
          Quote or otherwise communicated to Customer. If the Property conditions materially change from the time
          Contractor provided the Quote or if Contractor's ability to procure Goods or provide Services is impacted,
          Contractor may elect to cancel any portion of the Goods or Services or this Agreement in its entirety, and
          Contractor may bill for the portion of the Goods delivered and/or Services performed up to the date of
          cancelation or termination. In addition to Customer's obligation to pay any other applicable invoices, in the
          event that Customer requests to cancel any of the Goods or Services, or Contractor elects a Suspension, or
          Customer elects to terminate this Agreement pursuant to <u>Section 12</u>, in each case, Customer agrees and
          acknowledges that Contractor may invoice Customer for Quote Costs, and payment shall be made pursuant to the
          payment terms in <u>Section 4</u>. "<u>Quote Costs</u>" means costs and expenses reasonably incurred by
          Contractor relating to the Quote and in preparation of delivering the Goods and/or performing the Services,
          which may include, without limitation, overhead and labor costs in preparing the Quote, costs of
          scheduling/allocating labor, ordering or preparing materials or equipment, cancellation/return charges with
          respect to any Goods, and any expenses incurred by Contractor in connection with the Quote.
        </p>

        <p>
          <strong>6. Arbitration:</strong> Customer and Contractor stipulate and agree that any and all disputes or
          issues arising out of or relating to the Goods, Services and/or the this Agreement shall be subject to
          arbitration in accordance with the rules then prevailing of the judicial arbitration and mediation service ("
          <u>JAMS</u>") located in Orange County, California. The arbitrator may grant injunctions and other relief in
          such disputes. The decision of the arbitrator shall be final, conclusive and binding on the parties to the
          arbitration. Customer and Contractor agree that the prevailing party in any arbitration shall be entitled to
          injunctive relief in any court of competent jurisdiction to enforce the arbitration award. Customer and
          Contractor agree that the prevailing party in any arbitration or judicial proceeding shall be awarded its
          reasonable attorney's fees and costs. CUSTOMER AND CONTRACTOR EXPRESSLY ACKNOWLEDGE THAT THEY ARE WAIVING ANY
          RIGHT TO A JURY TRIAL FOR ANY AND ALL CLAIMS COVERED BY THIS PROVISION. Notwithstanding the preceding
          sentences in this <u>Section 6</u>, any disputes in the amount of $5,000 or less may be brought in small
          claims court located in Orange County, California, which is the location these terms are entered into.
        </p>

        <p>
          <strong>7. Customer's Property.</strong> Contractor is not responsible for the care, irrigation, and/or
          ongoing maintenance of the Goods or Property, except to the extent set forth in any applicable Monthly
          Landscape Agreement. Extent to the extent expressly set forth on the Quote as specifically included in the
          Services, the Services do not include (and Contractor shall be responsible whatsoever for the) inspection of
          the Property or its condition, including without limitation, the Property's trees, soil, irrigation, drainage
          or any other landscaping items or conditions. For the avoidance of doubt, professional engineering,
          architectural, and/or landscape design services are not included whatsoever in the Services and will not be
          provided by the Contractor.
        </p>

        <p>
          <strong>8. Independent Contractors:</strong> The relationship between the parties is that of independent
          contractors. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint
          venture, or other form or joint enterprise, employment, or fiduciary relationship between the parties, and
          neither party shall have authority to contract for or bind the other party in any manner whatsoever.
        </p>

        <p>
          <strong>9. Warranty Disclaimer</strong>
          <strong>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND NOTWITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT,
            THE GOODS AND SERVICES ARE PROVIDED "AS IS" AND "WITH ALL FAULT BASIS" WITHOUT WARRANTIES, CONDITIONS,
            REPRESENTATIONS OR GUARANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, STATUTORY, COURSE OF DEALING,
            COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR
            CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE.
          </strong>
        </p>

        <p>
          <strong>10. Indemnification:</strong> Customer shall indemnify, defend, and hold harmless Contractor and its
          successors, officers, directors, employees, agents, attorneys and insurance companies ("
          <u>Indemnified Parties</u>") from any and all actions, causes of action, claims, demands, costs, liabilities,
          expenses and damages (including attorneys' fees and costs) ("<u>Claims</u>") including claims regarding
          personal injury, death or property damage arising out of, or in connection with (i) any acts or omissions by
          Customer, including without limitation Customer's passive and/or active negligence relating to the Property,
          (ii) any breach of this Agreement by Customer, or (iii) the Property, the Goods or Services or the use
          thereof; provided, however, this indemnification shall not apply to Claims determined by a court or arbitral
          tribunal (as the case may be) of competent jurisdiction to have arisen from the gross negligence or willful
          misconduct of any Indemnified Party.
        </p>

        <p>
          <strong>11. Limitation Liability:</strong>
          <strong>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND NOTWITHSTANDING ANY OTHER PROVISION IN THIS
            AGREEEMENT, IN NO EVENT AND UNDER NO LEGAL THEORY, HOWEVER SO CAUSED INCLUDING ARISING IN CONTRACT, TORT
            (INCLUDING NEGLIGENCE AND GROSS NEGLIGENCE) OR OTHERWISE, CONTRACTOR AND ITS AFFILIATES, SUBSIDIARIES,
            AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, SUBCONTRACTORS AND INSUREDS SHALL NOT BE RESPONSIBLE TO CUSTOMER
            (AND ANY PARTY CLAIMING BY OR THROUGH CUSTOMER) OR ANY THIRD PARTY FOR CONSEQUENTIAL, INCIDENTAL, INDIRECT,
            PUNITIVE OR EXEMPLARY LOSSES OR DAMAGES, OR ANY LIABILITY FOR LOSS OF PROPERTY VALUE, GOODWILL OR PROFIT,
            COSTS OF PROCURING SUBSTITUTE PRODUCTS, MATERIALS OR SERVICES, OR LOSS OF USE OF PROPERTY OR OTHERWISE,
            REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT CUSTOMER HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL
            PURPOSE. IN NO EVENT SHALL CONTRACTOR'S AGGREGATE LIABLITY ARISING OUT OF OR RELATED TO THIS AGREEMENT,
            WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR
            OTHERWISE, EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER TO CONTRACTOR FOR THE GOODS AND SERVICES SOLD PURSUANT
            TO THE QUOTE.
          </strong>
        </p>

        <p>
          <strong>12. Termination:</strong> In addition to any remedies that may be provided under these Terms,
          Contractor may terminate this Agreement with immediate effect upon written notice to Customer, if Customer (i)
          fails to pay any amount when due under this Agreement or any other agreement between Contractor and Customer
          and such failure continues for 10 days after Customer's receipt of written notice of nonpayment, (ii) has not
          otherwise performed or complied with any of these Terms, or (iii) becomes insolvent, files a petition for
          bankruptcy or commences or has commenced against it proceedings relating to bankruptcy, receivership,
          reorganization, or assignment for the benefit of creditors.
        </p>

        <p>
          <strong>11. Miscellaneous:</strong>
          These Terms shall be interpreted and governed with reference solely to the laws of the State of California. If
          a court or an arbitrator of competent jurisdiction holds any provision of these Terms to be illegal,
          unenforceable, or invalid for any reason, the validity and enforceability of the remaining provisions of these
          Terms shall not be affected. The headings and captions in these Terms are included solely for convenience for
          reference and shall not affect the interpretation of any provision of these Terms. The language in all parts
          of these Terms shall be in all cases construed according to its fair meaning and any rule of construction to
          the effect that ambiguities are to be resolved against the drafting party shall not be applied in interpreting
          these Terms. A signed copy of the Quote (which, for avoidance of doubt, incorporates these Terms therein)
          delivered by facsimile, .pdf format, e-mail or other means of electronic transmission shall be deemed to have
          the same legal effect as delivery of an original signed copy. This Agreement is for the sole benefit of the
          parties hereto and their successors and assigns and nothing herein, express or implied, is intended to or
          shall confer upon any other person or entity any legal or equitable right benefit or remedy, except as
          otherwise set forth in Section 10. Provisions of these Terms which by their nature should apply beyond their
          terms will remain in force after any termination or expiration of this Agreement. These Terms may only be
          amended or modified in a writing stating specifically that it amends these Terms and is signed by an
          authorized representative of each party.
        </p>

        <p>
          FAILURE TO MAKE PAYMENT WHEN DUE FOR COMPLETED WORK ON CONSTRUCTION JOBS MAY RESULT IN A MECHANIC'S LIEN ON
          THE TITLE TO THE PROPERTY.
        </p>
      </Container>
    );
  }
}
