import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Header,
  Icon,
  Modal,
  Table,
  Dimmer,
  Loader
} from "semantic-ui-react";
import moment from "moment";
import { approveContract, denyContract } from "../../../actions/portal";
import AcceptStatus from "./AcceptStatus";
import AcceptedOn from "./AcceptedOn";
import "./Contracts.css";

function Item({ item, onDecision }) {
  const { accept_reject, accept_rejected_at } = item;

  return (
    <Table.Row>
      <Table.Cell>
        {/*<Link className="quote-edit" to={`/portal/quotes/view/${item.id}`}>*/}
        <strong>{item.name}</strong>
        <br />
        <a
          download
          className="item"
          href={`${process.env.REACT_APP_API_URL}/api/contracts/download/${
            item.id
          }`}
          target="_blank" rel="noreferrer"
        >
          Download
        </a>
        {/*</Link>*/}
      </Table.Cell>
      <Table.Cell>
        {moment(item.effective_date).format("MM-DD-YYYY")}
      </Table.Cell>
      <Table.Cell>{moment(item.term_end_date).format("MM-DD-YYYY")}</Table.Cell>

      <Table.Cell className="quote-item-status">
        {accept_reject === false && (
          <div className="status btn-denied">Denied</div>
        )}
        {accept_reject === true && (
          <div className="status btn-approved">Approved</div>
        )}
        {accept_reject === null && (
          <div className="status btn-in-progress">Draft</div>
        )}
      </Table.Cell>
      <Table.Cell className="approval-buttons">
        <AcceptedOn
          accept_reject={accept_reject}
          accept_rejected_at={accept_rejected_at}
        />
      </Table.Cell>
      <Table.Cell className="approval-buttons">
        <AcceptStatus
          id={item.id}
          onDecision={onDecision}
          accepted={accept_reject}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function getAcceptVerbiage() {
  return (
    <p>
      By checking accept below, you acknowledge and agree that (i) Customer, as
      named in the Contract, accepts the terms of Contract, (ii) you have
      authority to bind Customer to the Contract, and (iii) that the{" "}
      <a href="/terms" target="_blank">
        Contract Terms and Conditions
      </a>{" "}
      are incorporated into the Contract and govern the Contract.
    </p>
  );
}

class PortalContractList extends Component {
  state = {
    sort: {
      name: 0,
      description: 0
    },
    modalOpen: false
  };

  toItem = id => {
    const { entities } = this.props;
    const item = entities[id];
    if (!item) return;
    return (
      <Item
        key={id}
        item={item}
        onDelete={this.onDelete}
        onDecision={this.showModal}
      />
    );
  };

  sortByName = () => this.toggleSort("name");

  sortyByDescription = () => this.toggleSort("description");

  toggleSort = key => {
    const pageNum = this.props.pageNum;
    const dir = this.state.sort[key] ? "desc" : "asc";
    this.props.getQuotes(pageNum, key, dir);
    this.setState({ sort: { [key]: !this.state.sort[key] } });
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleDecision = choice => {
    if (choice === "ACCEPT") {
      this.props.dispatch(approveContract(this.state.portal_manager_id));
    } else if (choice === "DENY") {
      this.props.dispatch(denyContract(this.state.portal_manager_id));
    }
    this.handleClose();
  };

  showModal = (id, choice) => {
    this.setState({
      portal_manager_id: id,
      modalOpen: true,
      modalTitle:
        choice === "ACCEPT"
          ? "You are about to Approve this Contract"
          : "You are about to Deny this Contract",
      modalDescription: choice === "ACCEPT" ? getAcceptVerbiage() : ``,
      modalChoice: choice,
      modalConfirm: choice === "ACCEPT" ? "Yes, I Accept" : "Deny this Contract"
    });
  };

  render() {
    const { items, inProgress } = this.props;
    const {
      modalOpen,
      modalTitle,
      modalDescription,
      modalChoice,
      modalConfirm
    } = this.state;

    return (
      <div className="quotes-list-container">
        {Boolean(inProgress) && (
          <Dimmer active inverted>
            <Loader indeterminate>Loading</Loader>
          </Dimmer>
        )}

        <Modal open={modalOpen} onClose={this.handleClose} basic size="small">
          <Header icon="warning" content={modalTitle} />
          <Modal.Content>
            <p>{modalDescription}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={this.handleClose}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              inverted
              onClick={() => {
                this.handleDecision(modalChoice);
              }}
            >
              <Icon name="checkmark" /> {modalConfirm}
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.denyOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <Header
            icon="warning"
            content="You are about to Deny this Contract"
          />
          <Modal.Content />
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => {
                this.handleChoice("DENY");
              }}
            >
              <Icon name="remove" /> Cancel
            </Button>
            <Button color="green" inverted>
              <Icon name="checkmark" /> Yes, Deny this Contract
            </Button>
          </Modal.Actions>
        </Modal>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="quote-item-name">
                <strong className="sortable-header" onClick={this.sortByName}>
                  Job Name
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell collapsing>Effective Date</Table.HeaderCell>
              <Table.HeaderCell collapsing>Term End Date</Table.HeaderCell>
              <Table.HeaderCell collapsing>Status</Table.HeaderCell>
              <Table.HeaderCell collapsing>Accepted On</Table.HeaderCell>
              <Table.HeaderCell collapsing>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Boolean(items.length) && items.map(this.toItem)}
            {!Boolean(items.length) && (
              <Table.Row>
                <Table.Cell colSpan="6">
                  <b>You have No Contracts pending approval.</b>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

PortalContractList.defaultProps = {
  items: []
};

function mapStateToProps(state) {
  return {
    inProgress: state.contracts.inProgress
  };
}

export default connect(mapStateToProps)(PortalContractList);
