import React from "react";
import { render } from "react-dom";
import { browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import Root from "./containers/Root";

import "./index.css";
import "./css/buttons.css";
import "./css/react-select.css";
import "./css/common.css";

import "./css/AdminTop.css";
import "./css/Admin.css";
import "./css/Quotes.css";
import "./css/ViewQuote.css";

import { store } from "./store";

const history = syncHistoryWithStore(browserHistory, store);

render(<Root store={store} history={history} />, document.getElementById("root"));
