import React, { Component } from "react";
import { Link } from "react-router";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import "./Register.css";

export default class Register extends Component {
  state = {};

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onContinue();
    }
  };

  onContinue = () => {
    const { password, repeat_password } = this.state;

    if (password !== repeat_password) {
      alert("Passwords do not match. Please correct and try again");
      return;
    }
    this.props.onContinue(this.state.password);
  };

  render() {
    const { email } = this.props;
    return (
      <div className="register-container" onKeyPress={this.handleKeyPress}>
        <Grid
          textAlign="center"
          style={{ height: "100%", maxWidth: "500px", width: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column>
            <Header as="h1" color="red" textAlign="center">
              Welcome to<br />The Hub!<br /> The Future of Customer
              Integration
            </Header>

            <Header as="h3" textAlign="center">
              In order to proceed, you must first create a user account
            </Header>
            <p>
              Create a Password for your account to gain access to the Clear
              Net. This is where you will be able to view & approve all Quotes,
              Raise Requests, Contracts, and more relevant to your job sites.
            </p>

            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                disabled
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                name="password"
                type="password"
                onChange={this.handleInputChange}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Repeat Password"
                name="repeat_password"
                type="password"
                onChange={this.handleInputChange}
              />

              <Button color="red" fluid size="large" onClick={this.onContinue}>
                Continue
              </Button>
            </Segment>

            <Message>
              Already Registered? <Link to="/login">Login</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
