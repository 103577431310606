import { handleActions } from "redux-actions";
import { union } from "lodash";

const initialState = {
  contractIds: [],
  inProgress: false
};

export default handleActions(
  {
    PORTAL_CONTRACTS_GET_REQUEST: (state, { response }) => {
      return {
        ...state,
        inProgress: true
      };
    },

    PORTAL_CONTRACTS_GET_SUCCESS: (state, { response }) => {
      return {
        ...state,
        contractIds: union(response.result.items, state.quoteIds),
        inProgress: false
      };
    },

    PORTAL_CONTRACTS_GET_FAILURE: (state, { response }) => {
      return {
        ...state,
        inProgress: false
      };
    },

    APPROVE_CONTRACT_GET_REQUEST: (state, { response }) => {
      return {
        ...state,
        inProgress: true
      };
    },

    APPROVE_CONTRACT_GET_SUCCESS: (state, { response }) => {
      return {
        ...state,
        inProgress: false
      };
    },

    APPROVE_CONTRACT_GET_FAILURE: (state, { response }) => {
      return {
        ...state,
        inProgress: false
      };
    }
  },
  initialState
);
