import React from "react";

export default function PortalDashboard() {
  return (
    <div>
      <h1>Welcome to The Hub</h1>
      <p>The Hub is our newest way for customers to be connected </p>

      <div style={{ marginTop: "4rem" }}>
        <h3>Introduction Video </h3>
        <p>We made a video to help explain what The Hub is. Please take a second to watch it!</p>
        <iframe
          src="https://player.vimeo.com/video/310217862?title=false&byline=false"
          width="640"
          height="360"
          frameBorder="0"
          allowFullScreen
          title="OCLM - The Hub"
          style={{
            maxWidth: "100%"
          }}
        />
        <p>
          <a href="https://vimeo.com/310217862">The Hub 1.0 - Introduction</a> from{" "}
          <a href="https://vimeo.com/user69556095">O&#039;Connell Landscape</a> on <a href="https://vimeo.com">Vimeo</a>
          .
        </p>
      </div>
    </div>
  );
}
