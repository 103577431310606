import React from "react";
import { RadialChart } from "react-vis";

export default function DashboardPie({ data, width = 500, height = 500 }) {
  return (
    <RadialChart
      colorType="literal"
      showLabels={true}
      labelsStyle={{
        textTransform: "uppercase",
        fontWeight: "bold",
        fontFamily: "Arial",
        fontSize: "12px",
        fill: "white"
      }}
      labelsRadiusMultiplier={0.75}
      data={data}
      width={width}
      height={height}
    />
  );
}
