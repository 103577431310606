import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { resetClientPassword } from "../../actions/login";

class PortalLogin extends Component {
  onForgot = () => {
    const value = this.username.value;
    if (!value || value === "") {
      alert("Please enter in an email");
      return;
    }
    this.props.dispatch(resetClientPassword(this.username.value));
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onForgot();
    }
  };

  render() {
    const { errorMessage } = this.props;

    return (
      <div className="login-container" onKeyPress={this.handleKeyPress}>
        <div className="customer-login-box">
          <h1>Forgot Your Password?</h1>
          <p>
            Please enter your email below to and we will email you with a
            Password Reset Link.{" "}
          </p>
          <div style={{ color: "red" }}>{errorMessage}</div>

          <input
            className="textInput"
            type="text"
            ref={v => {
              this.username = v;
            }}
            placeholder="Email"
          />

          <button className="btnLogin" onClick={this.onForgot}>
            Reset Password
          </button>
          <Link to="/login">Go to Login</Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, token, errorMessage } = state.auth;
  return {
    token,
    isLoggedIn,
    errorMessage
  };
}

export default connect(mapStateToProps)(PortalLogin);
