export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4010";

export const READY_FOR_REVIEW = "READY_FOR_REVIEW";

export const AWAITING_OCLM_APPROVAL = "AWAITING_OCLM_APPROVAL";

export const AWAITING_CLIENT_APPROVAL = "AWAITING_CLIENT_APPROVAL";

export const APPROVED = "APPROVED";

export const DENIED = "DENIED";

export const IN_PROGRESS = "IN_PROGRESS";

export const SENT_TO_CLIENT = "READY_FOR_REVIEW";

export const MANAGER_APPROVED = "MANAGER_APPROVED";

export const QUOTE_COMPLETED = "QUOTE_COMPLETED";

export const FILTER_RESET = "FILTER_RESET";

export const TEMPLATE_TYPES = {
  DEFAULT: 1,
  WATSON: 2,
  LADERA: 3,
};

export const QUOTE_PRICE_NEEDS_APPROVAL = 2000;

export const LOGIN_CHECK_MS = 60000;

export const APPROVAL_TYPES = {
  1: SENT_TO_CLIENT,
  2: APPROVED,
  3: DENIED,
  4: AWAITING_OCLM_APPROVAL,
  5: MANAGER_APPROVED,
  6: QUOTE_COMPLETED,
};

export const API_ROOT = `${process.env.REACT_APP_API_URL}/api`;

export const BILLING_AREA_ALLOWLIST = ["james+client@functiona.com", "lott@mosaic.cc"];
