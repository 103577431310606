import React from "react";
import get from "lodash/get";
import { FlexibleWidthXYPlot, XAxis, YAxis, Hint, VerticalBarSeries } from "react-vis";

export default class MonthChart extends React.Component {
  static defaultProps = {
    data: {},
    hintRender() {}
  };

  state = {
    useCanvas: false
  };

  _rememberValue = value => {
    this.setState({ value });
  };

  _forgetValue = () => {
    this.setState({
      value: null
    });
  };

  render() {
    const { value } = this.state;
    const {
      data,
      title,
      color = "red",
      className,
      type = "count",
      // hintSuffix = "Quotes",
      hintRender,
      yearType = "current"
    } = this.props;
    return (
      <div style={{ width: "100%", position: "relative" }} className={className}>
        <h3>{title}</h3>
        <FlexibleWidthXYPlot xType="ordinal" height={400} colorType="literal" margin={{ left: 80, bottom: 70 }}>
          <XAxis tickLabelAngle={-45} />
          <YAxis />
          <VerticalBarSeries
            onValueMouseOver={this._rememberValue}
            onValueMouseOut={this._forgetValue}
            data={[
              { color, x: "January", y: get(data, `${yearType}.jan.${type}`, 0) },
              { color, x: "February", y: get(data, `${yearType}.feb.${type}`, 0) },
              { color, x: "March", y: get(data, `${yearType}.mar.${type}`, 0) },
              { color, x: "April", y: get(data, `${yearType}.apr.${type}`, 0) },
              { color, x: "May", y: get(data, `${yearType}.may.${type}`, 0) },
              { color, x: "June", y: get(data, `${yearType}.jun.${type}`, 0) },
              { color, x: "July", y: get(data, `${yearType}.jul.${type}`, 0) },
              { color, x: "August", y: get(data, `${yearType}.aug.${type}`, 0) },
              { color, x: "September", y: get(data, `${yearType}.sep.${type}`, 0) },
              { color, x: "October", y: get(data, `${yearType}.oct.${type}`, 0) },
              { color, x: "November", y: get(data, `${yearType}.nov.${type}`, 0) },
              { color, x: "December", y: get(data, `${yearType}.dec.${type}`, 0) }
            ]}
          />
          {value ? (
            <Hint horizontalAlign={Hint.ALIGN.RIGHT} verticalAlign={Hint.ALIGN.BOTTOM} value={value}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  borderWidth: 1,
                  borderColor: "gray",
                  borderStyle: "solid"
                }}
              >
                {hintRender(value)}
              </div>
            </Hint>
          ) : null}
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}
