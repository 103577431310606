import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import axios from "axios";
import { Button, Dropdown } from "semantic-ui-react";

import * as OPTIONS from "./options";

import "./QuoteFilters.css";

function makeRequest(url) {
  const options = {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNTIwODE3ODE3fQ.kt0_4eHaXBaShVkQ6OK7WZlQcuMN73JeVcYTAjYHid8`,
    },
  };

  return axios.get(url, options);
}

class QuoteFilters extends Component {
  state = {
    users: [],
  };
  componentDidMount() {
    Promise.all([makeRequest(`${process.env.REACT_APP_API_URL}/api/oclmusers`)]).then(([users]) => {
      this.setState({
        users: users.data,
      });
    });
  }

  render() {
    const { users } = this.state;
    const { open, onChange, onReset } = this.props;
    const { status, range, oclmuser } = this.props;

    const userOptions = OPTIONS.formatUsers(users);

    return (
      <div
        className={`${cx(
          {
            open: open,
          },
          "filters-panel"
        )}`}
      >
        <Dropdown
          value={status ? Number(status) : undefined}
          name="status"
          onChange={onChange}
          placeholder="Approval Status"
          search
          selection
          options={OPTIONS.APPROVAL_OPTIONS}
        />

        <Dropdown
          disabled={userOptions.length > 0 ? false : true}
          value={oclmuser ? Number(oclmuser) : undefined}
          name="oclmuser"
          onChange={onChange}
          placeholder="Submitted By"
          search
          selection
          options={userOptions}
        />

        <Dropdown
          value={range ? range : undefined}
          name="range"
          onChange={onChange}
          placeholder="Date Range"
          search
          selection
          options={OPTIONS.DATE_RANGE}
        />

        <Button color="red" inverted onClick={onReset}>
          Reset
        </Button>
      </div>
    );
  }
}

QuoteFilters.propTypes = {
  open: PropTypes.bool,
};

export default QuoteFilters;
