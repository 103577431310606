import moment from "moment";
import {
  READY_FOR_REVIEW,
  AWAITING_OCLM_APPROVAL,
  APPROVED,
  DENIED,
  IN_PROGRESS,
  QUOTE_COMPLETED
} from "../constants";

// get back the quote total based on quote items
export function getQuoteTotal(quoteItems) {
  return quoteItems.reduce((prev, { price, quantity, isDeleted }) => {
    if (!price || !quantity || isDeleted) return prev;
    return prev + price * quantity;
  }, 0);
}

export function formatQuoteNumber(id, created_at) {
  const year = moment(created_at).format("YYYY");
  const prefix = (id * 3).toString().slice(0, 5);
  const formattedQuoteNumber = year + prefix + id;
  return formattedQuoteNumber;
}

/**
 * iterate through quote items and update sort order
 **/
export function sortQuoteItems(quoteData) {
  return {
    ...quoteData,
    quotes: quoteData.quotes.map((item, idx) => {
      return {
        ...item,
        order: idx
      };
    })
  };
}

export function getApprovalStatus(approval_id, approval_user_id) {
  if (!approval_id && approval_user_id) {
    return {
      className: "btn-manager-approval-needed",
      label: "Needs Manager Approval",
      key: AWAITING_OCLM_APPROVAL
    };
  }

  if (approval_id === 1) {
    return {
      className: "btn-ready-for-review",
      label: "Ready for review",
      key: READY_FOR_REVIEW
    };
  }

  if (approval_id === 2) {
    return {
      className: "btn-approved",
      label: "Approved",
      key: APPROVED
    };
  }

  if (approval_id === 3) {
    return {
      className: "btn-denied",
      label: "Denied",
      key: DENIED
    };
  }
  
  if (approval_id === 6) {
    return {
      className: "btn-quote-completed",
      label: "Quote Completed",
      key: QUOTE_COMPLETED
    };
  }

  return {
    className: "btn-in-progress",
    label: "Draft",
    key: IN_PROGRESS
  };
}
