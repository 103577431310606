import * as React from "react";

export function toTitleCase(str) {
  return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const newlineRegex = /(\r\n|\r|\n)/g;

export function nl2br(str) {
  if (typeof str !== "string") {
    return str;
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }
    return line;
  });
}
