import React from "react";
import { Button, Header, Modal, Icon, Form } from "semantic-ui-react";

import "./ApproveQuoteModal.css";

// @param state - APPROVE, DENY
// @param header - Quote Approval Confirmation
export default class ApproveQuoteModal extends React.Component {
  sigCanvas = null;

  state = {
    signatureText: null,
  };

  onHandleChange = (e) => {
    this.setState({
      signatureText: e.target.value,
    });
    const value = e.target.value;
    console.log("value", value);
    let canvasTxt = this.canvasComponent.getContext("2d");
    canvasTxt.canvas.width = canvasTxt.measureText(value).width + 50;
    canvasTxt.canvas.height = 70;
    canvasTxt.font = "40px Nothing You Could Do";
    canvasTxt.color = "white";
    canvasTxt.fillText(value, 20, 50);

    this.setState({
      signature: canvasTxt.canvas.toDataURL(),
    });
  };
  render() {
    const {
      open,
      errorMessage,
      state,
      header,
      isLadera = false,
      onApprove,
      onCancel,
      onNotesChange,
      quoteLabel = "this quote",
    } = this.props;

    return (
      <Modal open={open}>
        <Header icon="warning" content={header} />
        <Modal.Content>
          <Form>
            <p>
              Are you sure you want to {state} {quoteLabel}?
            </p>
            {isLadera && (
              <p>
                By checking accept below, you acknowledge and agree that (i) Customer, as named in the Quote, accepts
                the terms of Quote, (ii) you have authority to bind Customer to the Quote, and (iii) that the{" "}
                <a href="/terms" target="_blank">
                  Quote Terms and Conditions
                </a>{" "}
                are incorporated into the Quote and govern the Quote.
              </p>
            )}

            <Form.TextArea
              name={"message"}
              label="Quote Notes"
              placeholder="Enter in notes"
              // value={notesValue}
              onChange={onNotesChange}
            />

            <h3>Signature</h3>
            <p>Please type in your Signature to Approve</p>
            <input
              type="text"
              value={this.state.signatureText}
              autoComplete="new-password"
              name="signatureText"
              onChange={this.onHandleChange}
            />
            <div className="signature-canvas-background">
              <canvas
                ref={(v) => {
                  this.canvasComponent = v;
                }}
              />
            </div>

            {Boolean(errorMessage) && <div className="error">{errorMessage}</div>}
            <br />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={onCancel}>
            <Icon name="remove" /> No, take me back
          </Button>
          <Button
            color="green"
            onClick={(e) => {
              onApprove(e, this.state.signature);
            }}
          >
            <Icon name="checkmark" /> Yes, I {state}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
