import { handleActions, combineActions } from "redux-actions";

const initialState = {
  isLoading: false,
  is404: false
};

export default handleActions(
  {
    [combineActions("QUOTEBYID_GET_FAILURE")]: state => {
      return {
        ...state,
        is404: true
      };
    }
  },
  initialState
);
