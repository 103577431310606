import { handleActions } from "redux-actions";
import cookie from "react-cookie";

const initialState = {
  // isLoggedIn: localStorage.getItem('token') ? true : false,
  // token: localStorage.getItem('token'),
  isLoggedIn: cookie.load("clientToken") ? true : false,
  token: cookie.load("clientToken"),
  errorMessage: ""
};

export default handleActions(
  {
    LOGOUT: () => {
      cookie.remove("token");
      return {
        isLoggedIn: false,
        token: "",
        errorMessage: ""
      };
    },

    CUSTOMER_LOGIN_POST_SUCCESS: (state, { response, ...action }) => {
      if (response.error) {
        return {
          isLoggedIn: false,
          token: "",
          errorMessage: response.message
        };
      }
      return {
        isLoggedIn: true,
        token: response.token,
        errorMessage: ""
      };
    },
    
  },
  initialState
);
