import { Schema, arrayOf, normalize } from "normalizr";
import axios from "axios";
import cookie from "react-cookie";

import { getPublicAccessToken } from "../utils/token";

const API_ROOT = `${process.env.REACT_APP_API_URL}/api`;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, method, data, authenticated, schema, external = false, headers = {}) => {
  const url = external ? endpoint : API_ROOT + endpoint;

  if (authenticated && getPublicAccessToken()) {
    headers = {
      ...headers,
      Authorization: `Bearer ${getPublicAccessToken()}`,
    };
  } else if (authenticated) {
    headers = {
      ...headers,
      Authorization: `Bearer ${cookie.load("clientToken")}`,
    };
  }

  return axios({
    headers,
    method,
    url,
    data,
  }).then((response) => {
    const data = response.data.payload || response.data;

    if (schema) return normalize(data, schema);
    return data;
  });
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

const userSchema = new Schema("users", {
  idAttribute: (user) => user.login.toLowerCase(),
});

const repoSchema = new Schema("repos", {
  idAttribute: (repo) => repo.fullName.toLowerCase(),
});

repoSchema.define({
  owner: userSchema,
});

// Schemas for Github API responses.
export const Schemas = {
  USER: userSchema,
  USER_ARRAY: arrayOf(userSchema),
  REPO: repoSchema,
  REPO_ARRAY: arrayOf(repoSchema),
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  // So the middleware doesn't get applied to every single action
  if (!action.meta || typeof action.meta.api === "undefined") return next(action);

  const { payload } = action;
  let { endpoint, onSuccess, onFailure } = action.meta;

  const { schema, types, method, authenticated, external, headers } = action.meta;

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  // if (!schema) {
  //   throw new Error('Specify one of the exported Schemas.')
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  // dont need to merge in this data
  // const actionWith = data => {
  //   const finalAction = Object.assign({}, action, data)
  //   delete finalAction[CALL_API]
  //   return finalAction
  // }

  const [requestType, successType, failureType] = types;
  next({ type: requestType });

  return callApi(endpoint, method, payload, authenticated, schema, external, headers)
    .then((response) => {
      next({
        response,
        type: successType,
        meta: { onSuccess },
      });
      return response;
    })
    .catch((error) => {
      next({
        type: failureType,
        error: error.message || "Something bad happened",
        meta: { onFailure },
      });
      throw new Error(error);
    });
};
