import { handleActions } from "redux-actions";

const initialState = {};

export default handleActions(
  {
    DASHBOARD_GET_SUCCESS: (state, { response }) => {
      return {
        ...state,
        ...response
      };
    }
  },
  initialState
);
