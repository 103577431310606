import React from "react";
import get from "lodash/get";
import { FlexibleWidthXYPlot, XAxis, YAxis, Hint, VerticalBarSeries } from "react-vis";

export default class QuarterChart extends React.Component {
  static defaultProps = {
    data: {}
  };

  state = {
    useCanvas: false
  };

  _rememberValue = value => {
    this.setState({ value });
  };

  _forgetValue = () => {
    this.setState({
      value: null
    });
  };

  render() {
    const { value } = this.state;
    const { data, title, color = "red" } = this.props;
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <h3>{title}</h3>
        <FlexibleWidthXYPlot margin={{ bottom: 70 }} xType="ordinal" height={400} colorType="literal">
          <XAxis />
          <YAxis />
          <VerticalBarSeries
            onValueMouseOver={this._rememberValue}
            onValueMouseOut={this._forgetValue}
            data={[
              { color, x: "Q1", y: get(data, "q1.count", 0) },
              { color, x: "Q2", y: get(data, "q2.count", 0) },
              { color, x: "Q3", y: get(data, "q3.count", 0) },
              { color, x: "Q4", y: get(data, "q4.count", 0) }
            ]}
          />
          {value ? (
            <Hint horizontalAlign={Hint.ALIGN.RIGHT} verticalAlign={Hint.ALIGN.BOTTOM} value={value}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  borderWidth: 1,
                  borderColor: "gray",
                  borderStyle: "solid"
                }}
              >
                <h3>{value.x}</h3>
                {value.y} Quotes
              </div>
            </Hint>
          ) : null}
        </FlexibleWidthXYPlot>
        <div>Total: {get(data, "year.count")} Quotes</div>
      </div>
    );
  }
}
