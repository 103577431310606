import { handleActions } from "redux-actions";

const initialState = {};

export default handleActions(
  {
    LOGGED_IN_USER_GET_SUCCESS: (state, action) => {
      return action.response;
    },
  },
  initialState
);
