import React from "react";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router";

import "./QuoteActions.css";

import { AWAITING_OCLM_APPROVAL, IN_PROGRESS } from "../../constants";

class QuoteActions extends React.Component {
  state = {
    approvalModalOpen: false,
    approval_error: "",
    approval_id: null,
  };

  render() {
    const { id, approvalStatus, onPreDenyQuote, onPreApproveQuote } = this.props;

    return (
      <div className="quote-actions-container">
        <div className="item submit-quote">
          {!Boolean(approvalStatus.key === IN_PROGRESS) && approvalStatus.key !== AWAITING_OCLM_APPROVAL && (
            <Button
              className="item"
              color="orange"
              onClick={() => {
                window.location = `${process.env.REACT_APP_API_URL}/api/quotes/download/${id}`;
                return;
              }}
            >
              Download PDF
            </Button>
          )}

          <Button className="item" color="red" onClick={onPreDenyQuote}>
            Deny Quote
          </Button>

          <Button className="item" color="green" onClick={onPreApproveQuote}>
            Approve Quote
          </Button>
        </div>
      </div>
    );
  }
}

QuoteActions.defaultProps = {
  onMarkAsCompleted() {},
  onQuoteReopen() {},
};

export default withRouter(QuoteActions);
