import React from "react";
import moment from "moment";
import Logo from "./OCLM-Logo-2023.jpg";
import numeral from "numeral";
import "./Watson.css";
import { nl2br } from "../../utils/string";

export default function Watson({ id, customer, created_at, name, description, notes, items }) {
  return (
    <div className="watson">
      <div className="view-quote-header">
        <img width="350" src={Logo} role="presentation" />
      </div>

      <h1>Watson # {id}</h1>

      <table className="tbl-quote-header">
        <tr>
          <td>
            <p>
              {customer.first_name} {customer.last_name}
            </p>
          </td>
          <td className="tbl-job-details">
            <p>
              <b>Date: </b>
              {moment(created_at).format("MM/DD/YYYY")}
              <br />
              <b>Submitted By: </b>[USERNAME]
              <br />
              <b>Job Name: </b>
              {name}
              <br />
              <b>Job Description: </b>
              {description}
            </p>
          </td>
        </tr>
      </table>

      <table className="tbl-quote">
        <thead>
          <th>Line No.</th>
          <th>Location</th>
          <th>Description</th>
          <th>Qty</th>
          <th>Type</th>
          <th>Price</th>
          <th>Total</th>
        </thead>
        <tbody>
          {items.map((obj, index) => {
            return (
              <tr key={obj.id}>
                <td>{index + 1}</td>
                <td>{obj.location}</td>
                <td>{obj.description}</td>
                <td>{obj.quantity}</td>
                <td>{obj.type}</td>
                <td>{obj.price}</td>
                <td>{numeral(obj.price * obj.quantity).format("$0,0.00")}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="quotes-notes-container">
        <label>Notes</label>
        <div className="quotes-notes-text">{nl2br(notes)}</div>
      </div>
    </div>
  );
}
