import React from "react";
import "./AdditionalApprovers.css";

export default function AdditionalApprovers({ managers }) {
  return (
    <div className="additional-approvers-container">
      <h3>Additional Approvers</h3>
      {Boolean(managers.length) && (
        <div className="users">
          <p>
            The following users have the ability to Accept or Reject this Quote
          </p>
          {managers.map(manager => (
            <div className="user">
              <label className="name">{manager.name}</label>
              <p className="email">
                {manager.management.company_name}
                <br />
                {manager.email}
              </p>
            </div>
          ))}
        </div>
      )}
      {!Boolean(managers.length) && (
        <span>
          <p>There are no Additional Approvers for this Quote</p>
        </span>
      )}
    </div>
  );
}

AdditionalApprovers.defaultProps = {
  managers: []
};
