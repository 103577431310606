import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import List from "./List";

import * as actions from "../../../actions/raise-requests";

import './RaiseRequests.css';

class PortalRaiseRequests extends Component {
  state = {
    showApproveModal: false
  };

  componentDidMount() {
    const pageNum = get(this.props, "location.query.p", 1);
    this.props.getPortalRaiseRequests(pageNum);
  }

  componentWillReceiveProps(nextProps) {
    const pageNum = get(this.props, "location.query.p", 1);
    const nextPageNum = get(nextProps, "location.query.p", 1);
    if (pageNum !== nextPageNum) {
      this.props.getPortalRaiseRequests(nextPageNum);
    }
  }

  render() {
    const { raiseRequestIds, raise_requests } = this.props;
    // const { showApproveModal } = this.state;

    return (
      <div className="section-wrapper">
        <h1>My Raise Requests</h1>

        <section className="section section--quotes">
          <div className="content-panel">
            <List
              items={raiseRequestIds}
              entities={raise_requests}
              getItems={this.props.getPortalRaiseRequests}
              pageNum={get(this.props, "location.query.p", 1)}
            />
          </div>
          <div className="action-panel">
            <div className="action-panel">
              {/* <Link className="item" to="/dashboard/quotes/create">
                <Button color="red" className="btn--create">Create a Quote</Button>
              </Link> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);
  const { raise_requests, entities } = state;
  const { raiseRequestIds } = raise_requests;
  return {
    raise_requests: entities.raise_requests,
    raiseRequestIds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PortalRaiseRequests
);
