import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import { Table } from "semantic-ui-react";
import n from "numeral";
import Pagination from "../../../components/Pagination";

import { TEMPLATE_TYPES } from "../../../constants";
import { getApprovalStatus } from "../../../utils/quotes";
import { approveQuote, denyQuote } from "../../../actions/quotes";
import ApproveQuoteModal from "../../../components/Modals/ApproveQuoteModal";
import { createQuoteMessage } from "../../../actions/quote_messages";

import "./Quotes.css";

function QuoteItem({ item, onApprove, onDeny }) {
  const status = getApprovalStatus(item.approval_id, item.approval_user_id);
  const showApproval = item.approval_id !== 2 && item.approval_id !== 3 && item.approval_id !== 6;
  return (
    <Table.Row>
      <Table.Cell>
        <a className="quote-edit" href={`/portal/quotes/view/${item.id}`} target="_blank" rel="noreferrer">
          {item.quote_no}
        </a>
      </Table.Cell>
      <Table.Cell>{item.portal_user ? item.portal_user.fullName : ""}</Table.Cell>
      <Table.Cell>
        <a className="quote-edit" href={`/portal/quotes/view/${item.id}`} target="_blank" rel="noreferrer">
          {item.name}
        </a>
      </Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>{n(item.total).format("$0,0.00")}</Table.Cell>
      <Table.Cell>
        <div className={status.className}>{status.label}</div>
      </Table.Cell>
      <Table.Cell>
        {Boolean(item.client_approved_on) && moment(item.client_approved_on).format("YYYY-MM-DD")}
      </Table.Cell>
      <Table.Cell>
        <ul className="quoteitem-actions">
          <li>
            <a className="quote-edit" href={`/portal/quotes/view/${item.id}`} target="_blank" rel="noreferrer">
              View
            </a>
          </li>
          <li>
            <a
              className="item"
              href={`${process.env.REACT_APP_API_URL}/api/quotes/download/${item.id}`}
              target="_blank" rel="noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
        {showApproval && (
          <ul className="quoteitem-actions">
            <li>
              <a
                href="#"
                className="green"
                onClick={() => {
                  onApprove(item);
                }}
              >
                Approve
              </a>
            </li>
            <li>
              <a
                href="#"
                className="red"
                onClick={() => {
                  onDeny(item);
                }}
              >
                Deny
              </a>
            </li>
          </ul>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

const modes = {
  APPROVE: {
    state: "APPROVE",
    header: "Quote Approval Confirmation",
  },
  DENY: {
    state: "DENY",
    header: "Quote Deny Confirmation",
  },
};

class PortalQuoteList extends Component {
  state = {
    sort: {
      name: 0,
      description: 0,
    },
    showDialog: false,
    mode: modes.APPROVE.state,
    message: "",
  };

  toItem = (id) => {
    const { quotes } = this.props;
    const item = quotes[id];
    if (!item) return;
    return <QuoteItem key={id} item={item} onApprove={this.onPreApproveQuote} onDeny={this.onPreDenyQuote} />;
  };

  toggleSort = (e, key) => {
    const dir = this.state.orderDir === "asc" ? "desc" : "asc";

    this.props.onFiltersChange(e, [
      {
        name: "orderBy",
        value: key,
      },
      {
        name: "orderDir",
        value: dir,
      },
    ]);
    this.setState({
      orderBy: key,
      orderDir: dir,
    });
  };

  onPreDenyQuote = (quote) =>
    this.setState({
      showDialog: true,
      mode: modes.DENY.state,
      approve_deny_quote: quote,
    });

  onPreApproveQuote = (quote) =>
    this.setState({
      showDialog: true,
      mode: modes.APPROVE.state,
      approve_deny_quote: quote,
    });

  onApproveQuote = (e, signature) => {
    // const { params } = this.props;
    const id = this.state.approve_deny_quote.id;
    const { message } = this.state;

    if (!signature) {
      this.setState({
        errorMessage: "Signature is required",
      });
      return false;
    }

    if (this.state.message.trim() === "" && this.state.mode === modes.DENY.state) {
      e.preventDefault();
      this.setState({
        errorMessage: "Please add in a Reason for Denial",
      });
      return false;
    }

    this.props.dispatch(
      createQuoteMessage({
        quote_id: id,
        message,
      })
    );

    if (this.state.mode === modes.APPROVE.state) {
      this.props.dispatch(
        approveQuote(id, {
          signature,
        })
      );
    } else if (this.state.mode === modes.DENY.state) {
      this.props.dispatch(denyQuote(id));
    }

    this.setState({
      showDialog: false,
    });
  };
  handleNotesChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { quoteIds, pagination } = this.props;

    return (
      <div className="quotes-list-container">
        <ApproveQuoteModal
          open={this.state.showDialog}
          onApprove={this.onApproveQuote}
          onCancel={() => this.setState({ showDialog: false })}
          onNotesChange={this.handleNotesChange}
          notesValue={this.state.message}
          state={modes[this.state.mode].state}
          header={modes[this.state.mode].header}
          isLadera={
            this.state.approve_deny_quote && this.state.approve_deny_quote.template_type !== TEMPLATE_TYPES.LADERA
          }
          errorMessage={this.state.errorMessage}
          quoteLabel={this.state.approve_deny_quote && this.state.approve_deny_quote.name}
        />
        <Table celled compact="very" size="small" stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="quote-item-no">Quote Number</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-name">Submitted By</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-name">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "name")}>
                  Job Name
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "description")}>
                  Description
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "total")}>
                  Total
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-status">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "approval_id")}>
                  Status
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "approval_id")}>
                  Approved On
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-actions">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Boolean(quoteIds.length) && quoteIds.map(this.toItem)}
            {!Boolean(quoteIds.length) && (
              <Table.Row>
                <Table.Cell colSpan="5">
                  <b>There are 0 Quotes to view.</b>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        <Pagination {...pagination} location={this.props.location} />
      </div>
    );
  }
}

export default connect()(PortalQuoteList);
