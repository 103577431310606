import { createAPIAction } from "redux-actions-api-addon";
import { push } from "react-router-redux";

export const resetPassword = createAPIAction(
  "RESET",
  "POST",
  ({ token }) => `/reset/${token}`,
  (data) => data,
  () => ({
    onSuccess(dispatch) {
      dispatch(push("/login?resetsuccess=true"));
    },
  })
);

export const getLoggedInUser = createAPIAction(
  "LOGGED_IN_USER",
  "GET",
  () => `/token`,
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export function handleLogin() {
  return function (dispatch) {
    return Promise.all([dispatch(getLoggedInUser())]);
  };
}
