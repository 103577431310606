import cookie from "react-cookie";

export default store => next => action => {
  switch (action.type) {
    case "CUSTOMER_LOGIN_POST_SUCCESS":
      if (action.response.error) {
        cookie.remove("clientToken");
        // localStorage.removeItem('token')
      } else {
        console.log("save cookie", action.response.token);
        cookie.save("clientToken", action.response.token, {
          path: "/"
        });
        // localStorage.setItem('token', action.response.token)
      }
      break;
    case "LOGOUT":
      cookie.remove("clientToken");
      // localStorage.removeItem('token')
      break;
    default:
      break;
  }
  next(action);
};
