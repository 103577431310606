import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { APPROVAL_TYPES, FILTER_RESET } from "../../constants";

const RESET_KEY = {
  key: FILTER_RESET,
  value: FILTER_RESET,
  text: "Reset"
};

export const APPROVAL_OPTIONS = [
  RESET_KEY,
  ...Object.keys(APPROVAL_TYPES)
    .filter(o => [1, 2, 3, 6].includes(Number(o)))
    .map(key => {
      return {
        key: APPROVAL_TYPES[key],
        value: Number(key),
        text: startCase(toLower(APPROVAL_TYPES[key].replace(/(-|_)/g, " ")))
      };
    })
];

export const DATE_RANGE = [
  RESET_KEY,
  {
    key: "today",
    value: "today",
    text: "Today"
  },
  {
    key: "thisweek",
    value: "thisweek",
    text: "This Week"
  },
  {
    key: "thismonth",
    value: "thismonth",
    text: "This Month"
  },
  {
    key: "last30",
    value: "last30",
    text: "Last 30 Days"
  }
];

export function formatUsers(users = []) {
  if (!users || !users.map) {
    return [];
  }
  return [
    RESET_KEY,
    ...users.map(user => {
      return {
        key: user.id,
        value: user.id,
        text: user.fullName
      };
    })
  ];
}
