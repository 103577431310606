import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import api from '../middleware/api'
import auth from '../middleware/auth'
import transition from '../middleware/transition'
import rootReducer from '../reducers'

import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';

const router = routerMiddleware(browserHistory);

const configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, router, api, auth, transition)
)

export default configureStore
