import React, { Component } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Link } from "react-router";
import { resetPassword } from "../../actions/auth";

class Reset extends Component {
  state = {
    errorMessage: ""
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onResetPassword();
    }
  };

  onResetPassword = () => {
    const { location } = this.props;

    if (this.password.value !== this.passwordConfirm.value) {
      this.setState({ errorMessage: "Passwords must match" });
      return false;
    }

    if (this.password.value.length < 7) {
      this.setState({
        errorMessage: "Passwords must be 8 characters or longer"
      });
      return false;
    }

    this.props.dispatch(
      resetPassword({
        email: get(location, "query.email"),
        password: this.password.value,
        token: get(location, "query.reset_token")
      })
    );
  };

  render() {
    const { errorMessage } = this.state;

    return (
      <div className="login-container" onKeyPress={this.handleKeyPress}>
        <div className="customer-login-box">
          <h1>Reset Your Password</h1>
          <div style={{ color: "red" }}>{errorMessage}</div>

          <input
            className="textInput"
            type="password"
            ref={v => {
              this.password = v;
            }}
            placeholder="New Password"
          />

          <input
            className="textInput"
            type="password"
            ref={v => {
              this.passwordConfirm = v;
            }}
            placeholder="Confirm Password"
          />

          <button className="btnLogin" onClick={this.onResetPassword}>
            Reset Password
          </button>
          <Link to="/login">Go to Login</Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, token, errorMessage } = state.auth;
  return {
    token,
    isLoggedIn,
    errorMessage
  };
}

export default connect(mapStateToProps)(Reset);
