import React from "react";
import "./ErrorPage.css";
import Logo from "../../images/OCLM-Logo-2023.jpg";

export default function ErrorPage() {
  return (
    <div className="errorbox">
      <img width="350" src={Logo} role="presentation" style={{ margin: "2rem auto" }} />
      <h3>You don't have access to this page</h3>
      <p>If you feel this is in error, please contact your OCLM Representative</p>
    </div>
  );
}
