import { Schema, arrayOf } from "normalizr";

const quote_item = new Schema("quote_items");

const customer = new Schema("customers", {
  idAttribute: "id"
});

const user = new Schema("users", {
  idAttribute: "id"
});

const asset = new Schema("assets", {
  idAttribute: "id"
});

const quote = new Schema("quotes", {
  idAttribute: "id"
});

const message = new Schema("messages", {
  idAttribute: "id"
});

const contract = new Schema("contracts", {
  idAttribute: "id"
});

const purchase_order = new Schema("purchase_orders", {
  idAttribute: "id"
});

const raise_request = new Schema("raise_requests", {
  idAttribute: "id"
});

quote.define({
  quote_items: arrayOf(quote_item),
  assets: arrayOf(asset),
  messages: arrayOf(message),
  user
});

purchase_order.define({
  purchase_order_items: arrayOf(purchase_order),
  user
});

export const Schemas = {
  MESSAGE: message,
  MESSAGES: arrayOf(message),
  CUSTOMER: customer,
  CUSTOMERS: arrayOf(customer),
  QUOTES: {
    items: arrayOf(quote)
  },
  QUOTE: quote,
  PURCHASE_ORDERS: {
    items: arrayOf(purchase_order)
  },
  PURCHASE_ORDER: purchase_order,

  CONTRACT: contract,
  CONTRACTS: {
    items: arrayOf(contract)
  },

  RAISE_REQUESTS: {
    items: arrayOf(raise_request)
  },
  RAISE_REQUEST: raise_request
};
