import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { Button, Modal, Header, Icon } from "semantic-ui-react";
import List from "./List";

import * as actions from "../../../actions/portal";

const resetModal = () => ({
  showApproveModal: false
});

class PortalContracts extends Component {
  state = {
    showApproveModal: false
  };

  componentDidMount() {
    const pageNum = get(this.props, "location.query.p", 1);
    this.props.getPortalContracts(pageNum);
  }

  componentWillReceiveProps(nextProps) {
    const pageNum = get(this.props, "location.query.p", 1);
    const nextPageNum = get(nextProps, "location.query.p", 1);
    if (pageNum !== nextPageNum) {
      this.props.getPortalContracts(nextPageNum);
    }
  }

  onApproveConfirm = () => {
    this.props.dispatch(actions.approveContract());
  };

  render() {
    const { contractIds, contracts } = this.props;
    const { showApproveModal } = this.state;

    return (
      <div className="section-wrapper">
        <h1>My Contracts</h1>

        <Modal open={showApproveModal} basic>
          <Header icon="refresh" content="Delete Quote Confirmation" />
          <Modal.Content>
            <p>Are you sure you want to approve this quote</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => this.setState(resetModal)}
            >
              <Icon name="remove" /> No
            </Button>
            <Button color="red" inverted onClick={this.onApproveConfirm}>
              <Icon name="checkmark" /> Yes, Approve the Quote
            </Button>
          </Modal.Actions>
        </Modal>

        <section className="section section--quotes">
          <div className="content-panel">
            <List
              items={contractIds}
              entities={contracts}
              getQuotes={this.props.getQuotes}
              deleteQuote={this.props.deleteQuote}
              pageNum={get(this.props, "location.query.p", 1)}
            />
          </div>
          <div className="action-panel">
            <div className="action-panel">
              {/* <Link className="item" to="/dashboard/quotes/create">
                <Button color="red" className="btn--create">Create a Quote</Button>
              </Link> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);
  const { contracts, entities } = state;
  const { contractIds } = contracts;
  return {
    contracts: entities.contracts,
    contractIds: contractIds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalContracts);
