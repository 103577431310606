import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {  withRouter } from "react-router";
import { Menu, Icon } from "semantic-ui-react";
import "./Pagination.css";

class Pagination extends React.Component {
  onNextPage = (pageNum) => {
    const { dispatch, location } = this.props;
    window.scrollTo(0, 0);

    dispatch(
      push({
        ...location,
        query: {
          ...location.query,
          p: pageNum,
        },
      })
    );
  };

  render() {
    const { page, pageCount } = this.props;
    const prevPage = page - 1;
    const nextPage = page + 1;
    const isFirstPage = page === 1;
    const isLastPage = page === pageCount;

    return (
      <div className="pagination-container">
        <Menu floated="right" pagination>
          {!isFirstPage && (
            <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(prevPage)}>
              <Icon name="left chevron" />
            </Menu.Item>
          )}

          {pageCount !== 1 && (
            <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(1)}>
              1
            </Menu.Item>
          )}

          {!isFirstPage && !(page - 1 === 1) && (
            <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(page - 1)}>
              {page - 1}
            </Menu.Item>
          )}

          {!isLastPage && !isFirstPage && (
            <Menu.Item icon className={`pagination-item`} color="red" active>
              {page}
            </Menu.Item>
          )}

          {!isLastPage && !(page + 1 === pageCount) && (
            <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(page + 1)}>
              {page + 1}
            </Menu.Item>
          )}

          <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(pageCount)}>
            {pageCount}
          </Menu.Item>

          {Boolean(!isLastPage) && (
            <Menu.Item icon className={`pagination-item`} onClick={() => this.onNextPage(nextPage)}>
              <Icon name="right chevron" />
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  }
}

export default connect()(withRouter(Pagination));
