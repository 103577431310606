
import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";

export const getPortalRaiseRequests = createAPIAction(
  "PORTAL_RAISE_REQUESTS",
  "GET",
  "/portal/raise-requests",
  null,
  () => ({
    authenticated: true,
    schema: Schemas.RAISE_REQUESTS,
    onSuccess(dispatch, { response }) {
      // dispatch(push("/dashboard/quotes"));
    }
  })
);

export const approveRaiseRequest = createAPIAction(
  "APPROVE_RAISE_REQUEST",
  "GET",
  id => `/portal/raise-requests/approval/${id}?acceptanceType=1`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.RAISE_REQUEST,
    onSuccess(dispatch, { response }) {
      console.log("response", response);
      // dispatch(push("/dashboard/quotes"));
    }
  })
);

export const denyRaiseRequest = createAPIAction(
  "DENY_RAISE_REQUEST",
  "GET",
  id => `/portal/raise-requests/approval/${id}?acceptanceType=0`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.RAISE_REQUEST,
    onSuccess(dispatch, { response }) {
      // dispatch(push("/dashboard/quotes"));
    }
  })
);
