import { push } from "react-router-redux";
import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import qs from "querystring-es3";

import { getPublicAccessToken } from "../utils/token";

export const downloadQuote = createAPIAction(
  "QUOTE_DOWNLOAD``",
  "POST",
  (id) => `/quotes/download/${id}`,
  null,
  () => ({
    authenticated: true,
  })
);

export const approveQuote = createAPIAction(
  "QUOTE_APPROVE",
  "POST",
  (id) => `/quotes/approve/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, { response }) {
      if (getPublicAccessToken()) {
        window.location.reload();
      } else {
        dispatch(push(`/portal/quotes`));
      }
    },
  })
);

export const denyQuote = createAPIAction(
  "QUOTE_DENY",
  "GET",
  (id) => `/quotes/deny/${id}`,
  (data) => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, { response }) {
      dispatch(push(`/portal/quotes`));
      // window.location.reload();
    },
  })
);

// CRUD update
export const crudUpdateQuote = createAPIAction(
  "CRUD_UPDATE_QUOTE",
  "PUT",
  (id) => `/quotes/crud/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
  })
);

export const editQuote = createAPIAction(
  "EDIT_QUOTE",
  "PUT",
  (id) => `/quotes/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, action) {
      // const { id } = action.response.model;
      // dispatch(push(`/dashboard/quotes/view/${id}`));
    },
  })
);

export const getQuotes = createAPIAction(
  "QUOTES",
  "GET",
  (data) => {
    const filteredData = pickBy(data, identity);
    const querystring = qs.stringify(filteredData);
    return `/quotes?${querystring}`;
  },
  null,
  () => ({
    authenticated: true,
    schema: Schemas.QUOTES,
  })
);

export const getQuoteById = createAPIAction(
  "QUOTEBYID",
  "GET",
  (id) => `/quotes/${id}`,
  null,
  (id) => ({
    authenticated: true,
    schema: Schemas.QUOTE,
  })
);

export const logout = createAction("LOGOUT");
