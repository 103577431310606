import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { push } from "react-router-redux";

export const customerLogin = createAPIAction(
  "CUSTOMER_LOGIN",
  "POST",
  "/login",
  (email = "", password = "") => ({
    email,
    password
  }),
  () => ({
    onSuccess(dispatch, action) {
      // debugger;
      // dispatch(push("/portal"));
    }
  })
);

export const logout = createAction("LOGOUT");

export const resetClientPassword = createAPIAction(
  "RESET_CLIENT",
  "POST",
  "/portal/reset",
  email => ({
    email
  }),
  () => ({
    onSuccess(dispatch, action) {
      dispatch(push("/login?didreset=true"));
    }
  })
);
