import React from "react";
import { Route, IndexRoute } from "react-router";
import App from "./containers/App";
import RegistrationSteps from "./containers/Register/RegistrationSteps";
import Forgot from "./containers/Forgot";
import Reset from "./containers/Reset";
import Terms from "./containers/Terms";

import PortalPage from "./containers/Portal";
import PortalDashboard from "./containers/PortalDashboard";
import PortalLogin from "./containers/PortalLogin";
import PortalQuotes from "./containers/Portal/Quotes";
import PortalViewQuote from "./containers/Portal/Quotes/View";
import PortalContracts from "./containers/Portal/Contracts";
import PortalViewContract from "./containers/Portal/Contracts/View";
import PortalRaiseRequests from "./containers/Portal/RaiseRequests";
import PortalViewRaiseRequest from "./containers/Portal/RaiseRequests/View";
import Dashboard from "./containers/Dashboard/Dashboard";
import Export from "./containers/Export/Export";

import SearchPage from "./containers/Search/SearchPage";
import * as onEnter from "./routes/functions";

export default (
  <Route path="/" component={App}>
    <IndexRoute onEnter={onEnter.loginOrDashboard} />

    <Route path="/login" component={PortalLogin} />

    <Route path="/forgot" component={Forgot} />
    <Route path="/reset" component={Reset} />

    <Route path="/terms" component={Terms} />

    <Route path="/register" component={RegistrationSteps} onEnter={onEnter.checkRegister} />

    <Route path="/portal" component={PortalPage} onEnter={onEnter.checkPortalAuth}>
      <IndexRoute component={PortalDashboard} />
      <Route path="dashboard" component={Dashboard} />
      <Route path="export" component={Export} />
      <Route path="contracts" component={PortalContracts} />
      <Route path="contracts/view/:id" component={PortalViewContract} />
      <Route path="raise-requests" component={PortalRaiseRequests} />
      <Route path="raise-requests/view/:id" component={PortalViewRaiseRequest} />
      <Route path="quotes" component={PortalQuotes} />
      <Route path="quotes/view/:id" component={PortalViewQuote} />

      <Route path="search" component={SearchPage} />
    </Route>
  </Route>
);
