import * as React from "react";
import { Button, Form } from "semantic-ui-react";

export default class BillingArea extends React.Component {
  textInput = null;

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.textInput.value);
  };

  setTextInputRef = (element) => {
    this.textInput = element;
  };

  render() {
    const { defaultValue = "" } = this.props;
    return (
      <div style={{ width: "400px" }} className={"quote-messages-container"}>
        <h3>Billing Area</h3>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <input defaultValue={defaultValue} placeholder="Enter Billing Area" ref={this.setTextInputRef} />
          </Form.Field>
          <Button type="submit" primary>
            Set Billing Area
          </Button>
          {this.props.success && <span style={{ color: "green" }}>Saved!</span>}
        </Form>
      </div>
    );
  }
}
