import React from "react";
import { withRouter } from "react-router";
import { Table } from "semantic-ui-react";
import axios from "axios";
import cookie from "react-cookie";
import { formatQuoteNumber, getApprovalStatus } from "../../utils/quotes";

import "./SearchPage.css";

class SearchPage extends React.Component {
  state = {
    results: [],
  };
  async componentDidMount() {
    const { location } = this.props;
    const { query } = location.query;

    const results = await this.getData(query);
    this.setState({
      results,
    });
  }
  getData = (input) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/quotes/search`,
        {
          query: input,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.load("clientToken")}`,
          },
        }
      )
      .then((response) => response.data.items);
  };

  render() {
    const { results } = this.state;
    const { location } = this.props;
    const { query } = location.query;

    return (
      <div>
        <h1>Search Results for: {query}</h1>

        {!results.length && <div>No Results</div>}
        {results.length && (
          <div>
            <h2>Quotes</h2>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Quote No.</Table.HeaderCell>
                  <Table.HeaderCell>Job Name</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "200px" }}>Status</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {results.map((result) => {
                  const status = getApprovalStatus(
                    result.approval_id,
                    result.approval_user_id,
                    result.manager_approved_on
                  );
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <a href={`/portal/quotes/view/${result.id}`} target="_blank" rel="noreferrer">
                          {formatQuoteNumber(result.id, result.created_at)}
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        {result.name}
                        <br />
                        <span className="SearchPage--description">{result.description}</span>
                      </Table.Cell>
                      <Table.Cell>
                        <div className={status.className}>{status.label}</div>
                      </Table.Cell>

                      <Table.Cell>
                        <a href={`/portal/quotes/view/${result.id}`} target="_blank" rel="noreferrer">
                          View
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SearchPage);
