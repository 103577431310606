import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import get from "lodash/get";
import StepOne from "./";
import AcceptTerms from "../../components/AcceptTerms";
import { resetPassword } from "../../actions/auth";

class RegistrationSteps extends React.Component {
  state = {
    step: 1,
    password: ""
  };

  onContinue = password => {
    console.log("continue");
    this.setState({
      step: 2,
      password
    });
  };

  onAccept = () => {
    const { location } = this.props;
    this.props.dispatch(
      resetPassword({
        email: get(location, "query.email"),
        password: this.state.password,
        token: get(location, "query.token"),
        accepted_on: moment().format()
      })
    );
  };

  render() {
    const { location } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return (
        <StepOne
          email={get(location, "query.email", "")}
          onContinue={this.onContinue}
          {...this.props}
        />
      );
    } else if (step === 2) {
      return <AcceptTerms onAccept={this.onAccept} {...this.props} />;
    }
    return <div />;
  }
}

export default connect()(RegistrationSteps);
