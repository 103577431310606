
import React from 'react';
import './QuoteImages.css';

export default function QuoteImages({ images = [] }) {

  if (!images.length) {
    return (<div />)
  };

  return (
    <div className="quote-images-container">
      <h3>Quote Images</h3>

      <div className="preview-items">
        {images.map(img => {
          return (
            <div className="quote-images-item preview-item">
              <img src={`http://functiona.s3.amazonaws.com/${img.key}`} role="presentation" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
