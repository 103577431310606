import PropTypes from 'prop-types';
import React from "react";
import moment from "moment";
import Logo from "./OCLM-Logo-2023.jpg";
import numeral from "numeral";
import { formatQuoteNumber, getQuoteTotal } from "../../utils/quotes";
import "./Default.css";
import { nl2br } from "../../utils/string";

export default function Default({ id, manager = {}, created_at, name, description, notes, items, user }) {
  return (
    <div>
      <div className="view-quote-header">
        <img width="350" src={Logo} role="presentation" />
      </div>

      <h1>Quote # {formatQuoteNumber(id, created_at)}</h1>

      <div className="tbl-quote-header">
        <div className="tbl-quote-header-item">
          <p>
            Customer Information:
            <br />
            {manager.name}
            <br />
            {manager.email}
            <br />
            {manager.phone}
          </p>
        </div>
        <div className="tbl-quote-header-item">
          <p>
            <b>Date: </b>
            {moment(created_at).format("MM/DD/YYYY")}
            <br />
            <b>Submitted By: </b>
            {user.fullName}
            <br />
            <b>Email: </b>
            {user.email}
            <br />
            <b>Job Name: </b>
            {name}
            <br />
            <b>Job Description: </b>
            {description}
          </p>
        </div>
      </div>

      <div className="tbl-quote">
        <div className="tbl-quote-row tbl-headers">
          <div className="tbl-quote-item">Line No.</div>
          <div className="tbl-quote-item">Location</div>
          <div className="tbl-quote-item">Description</div>
          <div className="tbl-quote-item">Qty</div>
          <div className="tbl-quote-item">Type</div>
          <div className="tbl-quote-item">Price</div>
          <div className="tbl-quote-item">Total</div>
        </div>
        {items.map((obj, index) => {
          return (
            <div className="tbl-quote-row" key={obj.id}>
              <div className="tbl-quote-item">{index + 1}</div>
              <div className="tbl-quote-item">{obj.location}</div>
              <div className="tbl-quote-item">{obj.description}</div>
              <div className="tbl-quote-item">{obj.quantity}</div>
              <div className="tbl-quote-item">{obj.type}</div>
              <div className="tbl-quote-item">{obj.price}</div>
              <div className="tbl-quote-item">{numeral(obj.price * obj.quantity).format("$0,0.00")}</div>
            </div>
          );
        })}
      </div>

      <div className="quote-total">
        <b>Total: {numeral(getQuoteTotal(items)).format("$0,0.00")}</b>
      </div>

      <div className="quotes-notes-container">
        <label>Notes</label>
        <div className="quotes-notes-text">{nl2br(notes)}</div>
      </div>
    </div>
  );
}

Default.defaultProps = {
  customers: {},
  user: {},
};

Default.propTypes = {
  user: PropTypes.object,
};
