import { store } from "../store";
import { handleLogin } from "../actions/auth";
import { getPublicAccessToken } from "../utils/token";

export function loginOrDashboard(nextState, replace) {
  const state = store.getState();
  const { isLoggedIn } = state.auth;

  if (isLoggedIn === true) {
    replace("/portal");
  } else {
    replace("/login");
  }
}

export function checkRegister(nextState, replace) {
  const { email, token } = nextState.location.query;

  if (!email || !token) {
    replace("/login");
  }
}

/**
 * Portal Auth
 **/
export function checkPortalAuth(nextState, replace) {
  const { email, reset_token } = nextState.location.query;
  // if we have a token, then the user needs to complete registration
  if (reset_token && email) {
    replace(`/register?token=${reset_token}&email=${encodeURIComponent(email)}`);
    return false;
  }

  const state = store.getState();
  const { isLoggedIn } = state.auth;

  if (getPublicAccessToken()) {
    return;
  }

  if (isLoggedIn) {
    store.dispatch(handleLogin()).catch(err => {
      window.location.href = `${process.env.REACT_APP_CLIENTS_APP_URL}/login`;
      replace("/redirect");
    });
  } else {
    // if no token, redirect to login
    replace(`/login?redirect_to=${window.location.pathname}`);
  }
}
