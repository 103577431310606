import React, { Component } from "react";
import { Link } from "react-router";

import { connect } from "react-redux";
import { Breadcrumb,  Dimmer, Loader } from "semantic-ui-react";
import { getQuoteById, approveQuote, denyQuote, crudUpdateQuote } from "../../../actions/quotes";
import { formatQuoteNumber, getApprovalStatus, getQuoteTotal } from "../../../utils/quotes";

import { Default, Watson } from "../../../components/QuoteTemplates";
import QuoteImages from "../../../components/QuoteImages";
import QuoteNotes from "../../QuoteNotes";
import { createQuoteMessage } from "../../../actions/quote_messages";
import AdditionalApprovers from "../../../components/AdditionalApprovers/AdditionalApprovers";
import QuoteActions from "../../../components/QuoteActions";
import ApproveQuoteModal from "../../../components/Modals/ApproveQuoteModal";
import BillingArea from "../BillingArea/BillingArea";
import { TEMPLATE_TYPES, BILLING_AREA_ALLOWLIST } from "../../../constants";

const modes = {
  APPROVE: {
    state: "APPROVE",
    header: "Quote Approval Confirmation",
  },
  DENY: {
    state: "DENY",
    header: "Quote Deny Confirmation",
  },
};

class ViewQuote extends Component {
  state = {
    showDialog: false,
    mode: modes.APPROVE.state,
    message: "",
    notes: "",
  };

  componentDidMount() {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(getQuoteById(id));
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.showDialog === true && this.state.message !== nextState.message) {
      return false;
    }
    return true;
  }

  onPreApproveQuote = () => this.setState({ showDialog: true, mode: modes.APPROVE.state });

  onPreDenyQuote = () => this.setState({ showDialog: true, mode: modes.DENY.state });

  onApproveQuote = (e, signature) => {
    const { params } = this.props;
    const { id } = params;
    const { message } = this.state;

    if (!signature) {
      this.setState({
        errorMessage: "Signature is required",
      });
      return false;
    }

    if (this.state.message.trim() === "" && this.state.mode === modes.DENY.state) {
      e.preventDefault();
      this.setState({
        errorMessage: "Please add in a Reason for Denial",
      });
      return false;
    }

    this.props.dispatch(
      createQuoteMessage({
        quote_id: id,
        message,
      })
    );

    if (this.state.mode === modes.APPROVE.state) {
      this.props.dispatch(
        approveQuote(id, {
          signature,
        })
      );
    } else if (this.state.mode === modes.DENY.state) {
      this.props.dispatch(denyQuote(id));
    }

    this.setState({
      showDialog: false,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmitSaveBillingArea = (value) => {
    const { params, dispatch } = this.props;
    const { id } = params;
    dispatch(
      crudUpdateQuote(id, {
        billing_area: value,
      })
    ).then(() => {
      dispatch(getQuoteById(id));

      this.setState({
        billingAreaSuccess: true,
      });
      setTimeout(() => {
        this.setState({
          billingAreaSuccess: false,
        });
      }, 2000);
    });
  };

  onNotesChange = (val) => this.setState({ notes: val });

  onAddNote = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(getQuoteById(id));
  };

  render() {
    const {
      additional_managers,
      created_at,
      images,
      template_type,
      approval_id,
      approval_user_id,
      manager_approved_on,
      messages = [],
      params,
      items,
      id,
      loggedInUser,
    } = this.props;
    console.log("rendering view");
    const { showDialog, mode } = this.state;
    const showApproval = approval_id !== 2 && approval_id !== 3 && approval_id !== 6;

    const approvalStatus = getApprovalStatus(approval_id, approval_user_id, manager_approved_on);

    const quoteId = params.id;

    const quoteTotal = getQuoteTotal(items);

    if (!id) {
      return (
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      );
    }
    return (
      <div className="section-wrapper">
        <ApproveQuoteModal
          open={showDialog}
          onApprove={this.onApproveQuote}
          onCancel={() => this.setState({ showDialog: false })}
          onNotesChange={this.handleChange}
          notesValue={this.state.message}
          state={modes[mode].state}
          header={modes[mode].header}
          isLadera={template_type !== TEMPLATE_TYPES.LADERA}
          errorMessage={this.state.errorMessage}
        />

        <div className="quote-top-section">
          <Breadcrumb className="quote-breadcrumbs">
            <Breadcrumb.Section link>Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section link>
              <Link to="/portal/quotes">Quotes</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Quote: {formatQuoteNumber(quoteId, created_at)}</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <h1>View Quote</h1>
        <p>
          <span className={`btn ${approvalStatus.className}`}>{approvalStatus.label}</span>
        </p>
        <section className="section section--quotes">
          <div className="action-panel">
            {showApproval && (
              <QuoteActions
                id={quoteId}
                approvalStatus={approvalStatus}
                onPreDenyQuote={this.onPreDenyQuote}
                onPreApproveQuote={this.onPreApproveQuote}
                quote_total={quoteTotal}
              />
            )}
            <hr />
          </div>

          <div className="content-panel">
            {template_type === 1 && <Default {...this.props} />}

            {template_type === 2 && <Watson {...this.props} />}

            {template_type === 3 && <Default {...this.props} />}

            {template_type === 4 && <Default {...this.props} />}

            <QuoteImages images={images} />
          </div>
        </section>

        <AdditionalApprovers managers={additional_managers} />

        {BILLING_AREA_ALLOWLIST.includes(loggedInUser.email) && (
          <BillingArea
            success={this.state.billingAreaSuccess}
            defaultValue={this.props.billing_area}
            onSubmit={this.onSubmitSaveBillingArea}
          />
        )}

        <QuoteNotes
          onChange={this.onNotesChange}
          quote_id={this.props.params.id}
          messages={messages}
          onAddNote={this.onAddNote}
        />
      </div>
    );
  }
}

ViewQuote.defaultProps = {
  customer: {},
};

function mapStateToProps(state, ownProps) {
  // get id from parameter
  const { user: loggedInUser } = state;
  const { id } = ownProps.params;
  const { quotes, assets: assetEntities, quote_items: quoteItemEntities, users = {} } = state.entities;
  const quote = quotes[id] || {};
  const user = users[quote.user];

  const { assets = [], quote_items = [] } = quote;
  const items = quote_items.map((id) => quoteItemEntities[id]);

  // console.log('assets', quote);
  const images = assets.map((assetId) => assetEntities[assetId]);

  return {
    ...quote,
    items,
    images,
    user,
    loggedInUser,
  };
}

export default connect(mapStateToProps)(ViewQuote);
