
import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";

export const getPortalContracts = createAPIAction(
  "PORTAL_CONTRACTS",
  "GET",
  "/portal/contracts",
  null,
  () => ({
    authenticated: true,
    schema: Schemas.CONTRACTS,
    onSuccess(dispatch, { response }) {}
  })
);

export const approveContract = createAPIAction(
  "APPROVE_CONTRACT",
  "GET",
  id => `/portal/contracts/approval/${id}?acceptanceType=1`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.CONTRACT
  })
);

export const denyContract = createAPIAction(
  "DENY_CONTRACT",
  "GET",
  id => `/portal/contracts/approval/${id}?acceptanceType=0`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.CONTRACT,
    onSuccess(dispatch, { response }) {}
  })
);

export const logout = createAction("LOGOUT");
