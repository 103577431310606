import { handleActions } from "redux-actions";
import { union } from "lodash";

const initialState = {
  raiseRequestIds: []
};

export default handleActions(
  {
    PORTAL_RAISE_REQUESTS_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          raiseRequestIds: union(response.result.items, state.quoteIds),
          pagination: response.result.pagination
        }
      );
    }
  },
  initialState
);
