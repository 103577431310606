/**
 * Redux middleware to manage transitions
 */

const transition = ({ getState, dispatch }) => next => (action) => {
  if (!action.meta) { return next(action); }

  const { onSuccess, onFailure } = action.meta;

  if (typeof onSuccess === 'function') {
    onSuccess(dispatch, action);
  }
  if (typeof onFailure === 'function') {
    onFailure(dispatch, action);
  }

  return next(action);
};


export default transition;
